import './referenzen.scss';

import MarkdownContent from '../components/MarkdownContent';
import Page from '../components/Page';
import PropTypes from 'prop-types';
import React from 'react';
import camelCase from 'lodash/camelCase';
import cx from 'classnames';
import { graphql } from 'gatsby'

const CSS_CLASS = 'contentPage';

const isNotImpressum = title => title !== 'Impressum';

const Template = ({
  data: {
    footer: {
      htmlAst: footerAst,
    },
    page: {
      frontmatter: { title },
      htmlAst,
    },
  },
}) => (
  <Page
    title={title}
    className={cx(CSS_CLASS, `${CSS_CLASS}--${camelCase(title)}`)}
    footerAst={isNotImpressum(title) ? footerAst : undefined}
  >
    <MarkdownContent contentAst={htmlAst} />
  </Page>
);

Template.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape({
      htmlAst: PropTypes.object.isRequired,
    }).isRequired,
    page: PropTypes.shape({
      htmlAst: PropTypes.object.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query MarkupPageByPath($path: String!) {
    footer: markdownRemark(frontmatter: { type: { eq: "footer" }}) {
      htmlAst
    }

    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`;

export default Template;
